import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const SEO = (props) => (
  <StaticQuery
    query={detailsQuery}
    render={(data) => {
      const { title, description, image, url, children } = props;

      const {
        site: { siteMetadata },
        configJson: {
          homepageMetaTags: {
            metaOgTitle,
            metaOgType,
            metaOgUrl,
            metaOgImage,
            metaOgDescription,
            metaTwitterCard,
          },
        },
      } = data;

      const ogTitle = title;
      const ogImage = image || metaOgImage;
      const ogDescription = description || metaOgDescription;
      const ogUrl = url;

      return (
        <Helmet
          htmlAttributes={{
            lang: 'en',
          }}
          title={ogTitle}
          titleTemplate={
            ogTitle === siteMetadata.title ? '%s' : `%s - ${metaOgTitle}`
          }
          meta={[
            {
              name: 'description',
              content: ogDescription,
            },
            {
              property: 'og:title',
              content: ogTitle,
            },
            {
              property: 'og:description',
              content: ogDescription,
            },
            {
              property: 'og:type',
              content: metaOgType,
            },
            {
              name: 'og:image',
              content: ogImage,
            },
            {
              name: 'og:url',
              content: ogUrl,
            },
            {
              name: 'twitter:card',
              content: metaTwitterCard,
            },
            {
              name: 'twitter:creator',
              content: siteMetadata.author,
            },
            {
              name: 'twitter:title',
              content: ogTitle,
            },
            {
              name: 'twitter:description',
              content: ogDescription,
            },
            {
              name: 'twitter:image',
              content: ogImage,
            },
          ].filter((x) => x.content)}
        >
          {children}
          <script src="/ai.js" />
        </Helmet>
      );
    }}
  />
);

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    configJson {
      homepageMetaTags {
        metaOgTitle
        metaOgType
        metaOgUrl
        metaOgImage
        metaOgDescription
        metaTwitterCard
      }
    }
  }
`;
