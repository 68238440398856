import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';

const SubFooter = () => {
  const data = useStaticQuery(graphql`
    query SubFooterQuery {
      configJson {
        footer {
          copyrightText
        }
        company {
          fullName
        }
        theme {
          themeUrl
          themeAuthor
        }
      }
    }
  `);

  const { company, footer, theme } = data.configJson;

  return (
    <div className="sub-footer">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div>
              <div className="d-flex justify-content-between">
                <div className="copyright">
                  <span>
                    {'© Copyright '}
                    {company.fullName}
                    {` ${new Date().getFullYear()}. `}
                    {footer.copyrightText}
                  </span>
                  <Link to="/imprint/">Impressum</Link>{' '}
                  <Link to="/privacy-policy/">Datenschutzerklärung</Link>
                </div>

                <div className="copyright">
                  {'theme by '}
                  <a className="zerostatic" href={theme.themeUrl}>
                    {theme.themeAuthor}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubFooter;
